import React, { FC, useState, useEffect,useMemo } from "react";
import {
  Table,
} from "react-bootstrap";
import useApi from "components/hooks/useApi";
import TablePlaceholder from "components/shared/TablePlaceholder";
import EmptyPlaceholder from 'components/shared/emptyPlaceholder';
import {getAcademicHistory} from "services/exams";

const AcademicHistory = ({ profileData }) => {
  const orgId = localStorage.getItem("orgId");
  const branchId = localStorage.getItem("branchId");
  const branchAcademicBatchId = localStorage.getItem("branchAcademicBatchId");

  const {
    action: getRecords,
    data: records,
    loading: isLoading,
  } = useApi(getAcademicHistory);

  const academicRecordList = useMemo(() => {
    if (!records?.data) return [];
    return records?.data;
  }, [records?.data]);

  useEffect(() => {
    if (
      profileData?.academicClassId?._id &&
      profileData?.academicSectionId?._id
    ) {
      getRecords({
        studentBranchAcademicYearId: profileData?._id,
        branchId: branchId,
        branchAcademicBatchId: branchAcademicBatchId,
        academicClassId: profileData?.academicClassId?._id,
        academicSectionId: profileData?.academicSectionId?._id,
        subjectWise: true,
      });
    }
  }, [profileData]);

  return (
    <div>
      {isLoading ? (
        <TablePlaceholder noOfCopies={10} />
      ) : academicRecordList && academicRecordList.length ? (
        <Table className="theme-content-table" responsive>
          <thead>
            <tr>
              <th>Subject</th>
              <th>Exam Type</th>
              <th>Max. Marks</th>
              <th>Marks Obtained</th>
              <th>Grades</th>
            </tr>
          </thead>
          <tbody>
            {academicRecordList.map((item: any, index: any) => {
              return item?.exams?.map((data: any, idx: any) => (
                <>
                  <tr key={`${index}-${idx}`}>
                    {idx === 0 && (
                      <td
                        className=" table-border-left-col fw-bold"
                        style={{ background: "#FAFAFA" }}
                        rowSpan={item?.exams?.length}
                      >
                        <span className="d-flex justify-content-center align-item-center">
                          {item?.subjectName}
                        </span>
                      </td>
                    )}
                    <td className="table-border-left-col">{data?.name}</td>
                    <td>{data?.totalMarks}</td>
                    <td>{data?.marks}</td>
                    <td>{data?.grade}</td>
                  </tr>
                </>
              ));
            })}
          </tbody>
        </Table>
      ) : (
        <EmptyPlaceholder />
      )}
    </div>
  );
};

export default AcademicHistory