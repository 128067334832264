import axios from "utils/axios";

// API : v1/scholasticmarks/reportCard
export async function getReportCard(data) {
    try {
        return await axios.post(`scholasticmarks/reportCard`, data);

        
    } catch (error) {
        return error;
    }
}

// API : v1/subskills/getSubSkills
export async function getSubskills(data) {
    try {
        return await axios.post(`subskills/getSubSkills`, data);

        
    } catch (error) {
        return error;
    }
}

// API : v1/subskills/update
export async function updateSubSKills(data, id) {
    try {
        return await axios.put(`subskills/update/${id}`, data);
        
    } catch (error) {
        return error;
    }
}

// API : v1/subskills/delete
export async function deleteSubskill(id) {
    try {
        return await axios.delete(`subskills/delete/${id}`);

        
    } catch (error) {
        return error;
    }
}

// API : v1/assessments/create
export async function createAssesment(data) {
    try {
        return await axios.post(`assessments/create`, data);
        
    } catch (error) {
        return error;
    }
}

// API : v1/assessments/getAssessments
export async function getAssesments(data) {
    try {
        return await axios.post(`assessments/getAssessments`, data);

        
    } catch (error) {
        return error;
    }
}

// API : v1/assessments/update/${id}
export async function updateAssesment(data, id) {
    try {
        return await axios.put(`assessments/update/${id}`, data);
        
    } catch (error) {
        return error;
    }
}

// API : v1/scholasticgradegroups/create
export async function createScholasticGradegroup(data) {
    try {
        return await axios.post(`scholasticgradegroups/create`, data);
        
    } catch (error) {
        return error;
    }
}

// API : v1/scholasticgradegroups/getGradeGroups
export async function getScholasticGradegroups(data) {
    try {
        return await axios.post(`scholasticgradegroups/getGradeGroups`, data);

        
    } catch (error) {
        return error;
    }
}

// API : v1/scholasticgradegroups/update/${id}
export async function updateScholasticGradegroup(data, id) {
    try {
        return await axios.put(`scholasticgradegroups/update/${id}`, data);
        
    } catch (error) {
        return error;
    }
}

// API : v1/examAttributes/create
export async function createExamAttribute(data) {
    try {
        return await axios.post(`examAttributes/create`, data);
        
    } catch (error) {
        return error;
    }
}

// API : v1/examAttributes/getExamAttributes
export async function getExamAttributes(data) {
    try {
        return await axios.post(`examAttributes/getExamAttributes`, data);

        
    } catch (error) {
        return error;
    }
}

// API : v1/examAttributes/update/id
export async function updateExamAttribute(data, id) {
    try {
        return await axios.put(`examAttributes/update/${id}`, data);
        
    } catch (error) {
        return error;
    }
}

// API : v1/examAttributes/delete
export async function deleteExamAttribute(id) {
    try {
        return await axios.delete(`examAttributes/delete/${id}`);

        
    } catch (error) {
        return error;
    }
}

// API : v1/studentexamattributesmarks/getStudents
export async function getExamAttributeStudents(data) {
    try {
        return await axios.post(`studentexamattributesmarks/getStudents`, data);

        
    } catch (error) {
        return error;
    }
}

// API : v1/studentexamattributesmarks/create
export async function assignExamAttributeStudents(data) {
    try {
        return await axios.post(`studentexamattributesmarks/create`, data);

        
    } catch (error) {
        return error;
    }
}

// API : v1/examStructure/getexamtermsbyclass
export async function getTermsandExamTypes(data) {
    try {
        return await axios.post(`examStructure/getexamtermsbyclass`, data);

        
    } catch (error) {
        return error;
    }
}

// API : v1/generalexam/create
export async function createGeneralExam(data) {
    try {
        return await axios.post(`generalexam/create`, data);
        
    } catch (error) {
        return error;
    }
}

// API : v1/generalexam/getgeneralexams
export async function getGeneralExams(data) {
    try {
        return await axios.post(`generalexam/getgeneralexams`, data);

        
    } catch (error) {
        return error;
    }
}

// API : v1/generalexam/getstudents
export async function getGeneralExamStudents(data) {
    try {
        return await axios.post(`generalexam/getstudents`, data);

        
    } catch (error) {
        return error;
    }
}

// API : v1/generalexam/assignmarks
export async function assignGeneralExamMarks(data) {
    try {
       const res = await axios.post(`generalexam/assignmarks`, data);

        if(res?.status === 200){
            sendGeneralExamMarksForApproval({
                branchId: data?.branchId,
                branchAcademicBatchId: data?.branchAcademicBatchId,
                academicClassId: data?.academicClassId,
                academicSectionId: data?.academicSectionId,
                generalExamId: data?.generalExamId
            })
        }
        return res;
        
    } catch (error) {
        return error;
    }
}

// API : v1/generalexam/sendForApproval
export async function sendGeneralExamMarksForApproval(data) {
    try {
        return await axios.post(`generalexam/sendForApproval`, data);

        
    } catch (error) {
        return error;
    }
}

// API : v1/generalexam/approvalAction
export async function approveGeneralExamMarks(data) {
    try {
        return await axios.post(`generalexam/approvalAction`, data);

        
    } catch (error) {
        return error;
    }
}

// API : v1/generalexam/update
export async function updateGeneralExam(data) {
    try {
        return await axios.post(`generalexam/update`, data);
        
    } catch (error) {
        return error;
    }
}

// API : v1/healthandhygeniccomponents/create
export async function createHealthParameters(data) {
    try {
        return await axios.post(`healthandhygeniccomponents/create`, data);
        
    } catch (error) {
        return error;
    }
}

// API : v1/healthandhygeniccomponents/getcomponents
export async function getHealthParameters(data) {
    try {
        return await axios.post(`healthandhygeniccomponents/getcomponents`, data);

        
    } catch (error) {
        return error;
    }
}

// API : v1/healthandhygeniccomponents/update/id
export async function updateHealthParameters(data, id) {
    try {
        return await axios.put(`healthandhygeniccomponents/update/${id}`, data);
        
    } catch (error) {
        return error;
    }
}

// API : v1/healthandhygeniccomponents/delete/${id}
export async function deleteHealthParameters(id) {
    try {
        return await axios.delete(`healthandhygeniccomponents/delete/${id}`);

        
    } catch (error) {
        return error;
    }
}

// API : v1/healthandhygenicdetails/create
export async function createHeathSchedule(data) {
    try {
        return await axios.post(`healthandhygenicdetails/create`, data);
        
    } catch (error) {
        return error;
    }
}

// API : v1/healthandhygenicdetails/gethealthandhygenicdetails
export async function getHeathSchedules(data) {
    try {
        return await axios.post(`healthandhygenicdetails/gethealthandhygenicdetails`, data);

        
    } catch (error) {
        return error;
    }
}

// API : v1/healthandhygenicdetails/update/id
export async function updateHeathSchedule(data, id) {
    try {
        return await axios.put(`healthandhygenicdetails/update/${id}`, data);
        
    } catch (error) {
        return error;
    }
}

// API : v1/healthandhygenicdetails/delete
export async function deleteHeathSchedule(id) {
    try {
        return await axios.delete(`healthandhygenicdetails/delete/${id}`);

        
    } catch (error) {
        return error;
    }
}

// API : v1/studenthealthandhygenic/getStudents
export async function getHeathScheduleStudents(data) {
    try {
        return await axios.post(`studenthealthandhygenic/getStudents`, data);

        
    } catch (error) {
        return error;
    }
}

// API : v1/studenthealthandhygenic/create
export async function assignHeathScheduleStudents(data) {
    try {
        return await axios.post(`studenthealthandhygenic/create`, data);

        
    } catch (error) {
        return error;
    }
}

// API: v1/marksapprovalsettings/create
export async function createMarksApprovalSettings(data: any) {
    try {
        return await axios.post(`marksapprovalsettings/create`, data);
    } catch (error) {
        return error;
    }
}

// API: v1/marksapprovalsettings/update/${id}
export async function updateMarksApprovalSettings(data: any, id: any) {
    try {
        return await axios.post(`marksapprovalsettings/update/${id}`, data);
    } catch (error) {
        return error;
    }
}

// API: v1/marksapprovalsettings/get
export async function getMarksApprovalSettings(data: any) {
    try {
        return await axios.post(`marksapprovalsettings/get`, data);
    } catch (error) {
        return error;
    }
}

// API: v1/marksapprovalsettings/get
export async function getStudentTermsRemarks(data: any) {
    try {
        return await axios.post(`scholasticmarks/termremarks`, data);
    } catch (error) {
        return error;
    }
}


// ACADEMIC HISTORY
export async function getAcademicHistory(data: any) {
    try {
        return await axios.post(`scholasticmarks/getAcademicHistory`, data);
    } catch (error) {
        return error;
    }
}