import axios from "utils/axios";
import { setUserAuthToken } from "utils/headers";
import { setAlert } from "redux/actions/alert";



export async function getStaff(data) {
    try {
        let response = await axios.post(`staff/getstaff`, data);
        return response.data;
    } catch (error) {
        return error;
    }
}
export async function getUserAccountsByStaffId(staffId) {
    try {
        let response = await axios.get(`user/getUserAccounts/${staffId}`);
        return response.data;
    } catch (error) {
        return error;
    }
}
export async function createUserAccount(data) {
    try {
        let response = await axios.post(`user/createUserAccount`, data);
        return response.data;
    } catch (error) {
        return error;
    }
}
export async function unassignUserAccount(data) {
    try {
        let response = await axios.post(`user/deleteUserAccount`, data);
        return response.data;
    } catch (error) {
        return error;
    }
}

export async function getStaffDetails(data) {
    try {
        let response = await axios.post(`user/getStaff`, data);
        return response.data;
    } catch (error) {
        return error;
    }
}
export async function editHolidays(data, id) {
    try {
        let response = await axios.put(`holiday/update/${id}`, data);
        return response.data;
    } catch (error) {
        return error?.response;
    }
}


export async function getDepartmentByOrgId(data) {

    try {

        let response = await axios.post(`department/getDepartments`, data);
        return response.data;


    } catch (error) {

        return error

    }
}
export async function getDesignationByOrgId(orgId) {

    try {

        let response = await axios.get(`designation/getDesignation/${orgId}`);
        return response.data;


    } catch (error) {

        return error

    }
}


export async function getHolidaysCount(data) {
    try {
        let response = await axios.post(`holiday/getholidayscount`, data);
        return response.data;
    } catch (error) {
        return error?.response;
    }
}

// API: v1/department/create
export async function createDepartment(data) {
    try {
        return await axios.post(`department/initialsetup/create`, data);
    } catch (error) {
        return error;
    }
};

// API: v1/designation/create
export async function createDesignation(data) {
    try {
        return await axios.post(`designation/initialsetup/create`, data);
    } catch (error) {
        return error;
    }
};

// API: v1/leavetype/getLeaveTypes
export async function getLeaveType(data) {
    try {
        return await axios.get(`leavetype/getLeaveTypes/${data}`);
    } catch (error) {
        return error;
    }
}

// API: v1/leavetype/createLeaveTypes
export async function createLeaveType(data) {
    try {
        return await axios.post(`leavetype/createLeaveTypes`, data);
    } catch (error) {
        return error;
    }
};


// API: v1/leavePlan/createLeavePlans
export async function createLeavePlan(data) {
    try {
        return await axios.post(`leavePlan/createLeavePlans`, data);
    } catch (error) {
        return error;
    }
}

// API: v1/weeklyoff/getweeklyoff
export async function getWeeklyOffs(data) {
    try {
        let response = await axios.post(`weeklyoff/getweeklyoff`, data);
        return response.data;
    } catch (error) {
        return error?.response;
    }
}

// API: v1/leavePlan/getApproval
export async function getLeaveApprovals(data) {
    try {
        let response = await axios.post(`leavePlan/getApproval`, data);
        return response.data;
    } catch (error) {
        return error?.response;
    }
}

// API: v1/attendancesetting/staff/getSettingsBranchWise
export async function getStaffAttendanceSettings(data) {
    try {
        let response = await axios.post(`attendancesetting/staff/getSettingsBranchWise`, data);
        return response.data;
    } catch (error) {
        return error?.response;
    }
}

// API: v1/attendancesetting/staff/create
export async function CreateAndUpdateStaffAttendanceSettings(data) {
    try {
        let response = await axios.post(`attendancesetting/staff/create`, data);
        return response.data;
    } catch (error) {
        return error?.response;
    }
}

// API : v1/paygroup/getSalaryBreakup
export async function getSalaryBreakup(data: any,) {
    try {
        return await axios.post(`paygroup/getSalaryBreakup`, data);
    } catch (error) {
        return error;
    }
}

// API : v1/paygroup/getStaffPayDetails/
export async function getStaffPayDetails(id: any) {
    try {
        return await axios.get(`paygroup/getStaffPayDetails/${id}`);
    } catch (error) {
        return error;
    }
}


// API : v1/paygroup/downloadPaySlip
export async function downloadPaySlip(data: any, dispatch) {
    try {
        if (localStorage.user__token) setUserAuthToken(localStorage.user__token, localStorage.use_role_level);
        const response : any =  await axios.post(`paygroup/downloadPaySlip`, data, {
            headers: {
                "Content-Type": "application/json",
            },
            responseType: 'blob'       
        });

        var file = new Blob([response.data], {type: 'application/pdf'});
        const blobURL = window.URL.createObjectURL(file);
        var a: HTMLAnchorElement = document.createElement('a');
        a.href = blobURL;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        
    } catch(error){
        dispatch(setAlert({
            msg : error?.response?.data?.message ?? "Failed to Download Pay Slip",
            status : 500,
            alertType : "danger"
        }))
        throw error;
    }
};

// API : v1/paygroup/getPaySlipDetails
export async function getPaySlipDetails(data: any) {
    try {
        return await axios.post(`paygroup/getPaySlipDetails`, data);
    } catch (error) {
        return error;
    }
}


// API : v1/staffresignation/raiserequest
export async function raiseRequest(data: any) {
    try {
        return await axios.post(`staffresignation/raiserequest`, data);
    } catch (error) {
        return error;
    }
}



// API : v1/staffresignation/withdrawrequest
export async function withdrawRequest(data: any) {
    try {
        return await axios.post(`staffresignation/withdrawrequest`, data);
    } catch (error) {
        return error;
    }
}


// API : v1/staffresignation/approvalaction
export async function approvalAction(data: any) {
    try {
        return await axios.post(`staffresignation/approvalaction`, data);
    } catch (error) {
        return error;
    }
}


// API : v1/staffresignation/approvalrequests
export async function approvalRequests(data: any) {
    try {
        return await axios.post(`staffresignation/approvalrequests`, data);
    } catch (error) {
        return error;
    }
}


// API : v1/staffattendance/create
export async function createStaffAttendance(data: any) {
    try {
        return await axios.post(`staffattendance/create`, data);
    } catch (error) {
        return error;
    }
}

// API : v1/staffattendance/getAttendance
export async function getStaffForAttendance(data: any) {
    try {
        return await axios.post(`staffattendance/getAttendance`, data);
    } catch (error) {
        return error;
    }
}

// API : v1/staffattendance/getStaff
export async function getstaffAttendence(data: any) {
    try {
        return await axios.post(`staffattendance/getStaff`, data);
    } catch (error) {
        return error;
    }
}
// API : v1/holiday/map-departments
export async function mapDepartmentsToHolidays(data) {
    try {
        return await axios.post(`holiday/map-departments`, data);

    } catch (error) {
        return error;
    }
}

// API : v1/staffattendance/getAttendanceStats
export async function getLeaveReport(data) {
    try {
        return await axios.post(`staffattendance/getAttendanceStats`, data);

    } catch (error) {
        return error;
    }
}

// API : v1/documents/create
export async function createDocuments(data) {
    try {
        return await axios.post(`documents/create`, data);

    } catch (error) {
        return error;
    }
}

// API : v1/documents/delete
export async function deleteDocuments(data, id) {
    try {
        return await axios.delete(`documents/delete/${id}`, data);

    } catch (error) {
        return error;
    }
}

// API : v1/documents/get
export async function getDocuments(data) {
    try {
        return await axios.post(`documents/get`, data);

    } catch (error) {
        return error;
    }
}

// API : v1/documents/delete
export async function updateDocuments(data, id) {
    try {
        return await axios.put(`documents/update/${id}`, data);

    } catch (error) {
        return error;
    }
}